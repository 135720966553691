
import { butter } from '~/plugins/buttercms'

export default {
  async asyncData ({store}) {
    const prefix = 'pages/cookie-policy.vue'
    let promiseArray = []

    if (!store.state.butterSiteInfo) {
      let bpromise = butter.content
        .retrieve(['site_info'])
        .then((res) => {
          store.commit('saveButterSiteInfo', res.data.data.site_info)
          console.log(prefix+' retrieved: site_info')
        })
        .catch((err) => {
          console.log('error retrieving: site_info  '+err.message)
        })
      promiseArray.push(bpromise)
    } else {
      console.log(prefix+' already retrieved: site_info')
    }

    if (promiseArray.length) {
      await Promise.all(promiseArray)
    }
  },
}
